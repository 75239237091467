import Vue from 'vue'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    data: function() {
        return {
            base_url: Vue.BASE_URL,
            pageTitle: "Paramètre de l'application",
            params: [],
            listeCompte: [],
        }
    },
    methods: {

        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = that.BASE_URL + "/appparams/majaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchtb: function() {
            axios.get(this.BASE_URL + "/appparams/get").then(response => {
                // console.log(response.data.params_tb);
                this.params = response.data;
            });
        },
        getAllCompte() {
            axios.get(this.base_url+"/plancomptable/getall").then(response => {
                this.listeCompte = response.data;
            });

        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.fetchtb();
        this.getAllCompte();
    },
    mounted: function() {


    }
}